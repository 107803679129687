import { phoneValidation, emailValidation } from '../../../../../helper/validate';

export default (values, intl) => {
	const errors = {};

	if (!values.name) {
		errors.name = intl.formatMessage({ id: `validate_form.required` });
	}

	if (!values.legal_company) {
		errors.legal_company = intl.formatMessage({ id: `validate_form.required` });
	}

	if (!values.email_company) {
		errors.email_company = intl.formatMessage({ id: `validate_form.required` });
	}
	if (!emailValidation(values.email_company)) {
		errors.email_company = intl.formatMessage({ id: `validate_form.invalid_email` });
	}

	if (!values.filed_of_work) {
		errors.filed_of_work = intl.formatMessage({ id: `validate_form.required` });
	}

	if (!phoneValidation(values.phone) || values.phone.length < 8) {
		errors.phone = intl.formatMessage({ id: `validate_form.invalid_phone` });
	}

	if (!values.phone) {
		errors.phone = intl.formatMessage({ id: `validate_form.required` });
	}

	if (values.phone !== '' && values.phone[0] === '0') {
		errors.phone = intl.formatMessage({ id: `validate_form.invalid_phone_zero` });
	}

	return errors;
};
