import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
	.loadingEllipsisContainer {
		position: relative;
		padding: 0.8rem 2.2rem;
		margin-top: -0.7rem;
		margin-left: -0.5rem;
	}

	.ldsEllipsis div {
		position: absolute;

		width: 0.8rem;
		height: 0.8rem;
		border-radius: 50%;
		background: #ffffff;
		animation-timing-function: cubic-bezier(0, 1, 1, 0);
	}

	.ldsEllipsis div:nth-child(1) {
		left: 8px;
		animation: lds-ellipsis1 0.6s infinite;
	}

	.ldsEllipsis div:nth-child(2) {
		left: 8px;
		animation: lds-ellipsis2 0.6s infinite;
	}

	.ldsEllipsis div:nth-child(3) {
		left: 32px;
		animation: lds-ellipsis2 0.6s infinite;
	}

	.ldsEllipsis div:nth-child(4) {
		left: 56px;
		animation: lds-ellipsis3 0.6s infinite;
	}

	@keyframes lds-ellipsis1 {
		0% {
			transform: scale(0);
		}

		100% {
			transform: scale(1);
		}
	}

	@keyframes lds-ellipsis3 {
		0% {
			transform: scale(1);
		}

		100% {
			transform: scale(0);
		}
	}

	@keyframes lds-ellipsis2 {
		0% {
			transform: translate(0, 0);
		}

		100% {
			transform: translate(24px, 0);
		}
	}
`;

const EllipsisWhite = () => {
	return (
		<Wrapper>
			<div className="loadingEllipsisContainer">
				<div className="ldsEllipsis">
					<div />
					<div />
					<div />
					<div />
				</div>
			</div>
		</Wrapper>
	);
};

export default EllipsisWhite;
