import React, { useState } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import { Wrapper } from './style';
import { sendToWhatsapp } from '../../../../helper/redirect';
import { createApi, updateApi } from '../../../../api/cta';
import setContext from '../../../../api/setContext';
import sendMail from '../../../../api/mail';
import '../../../atoms/Input/Select/modal.scss';

const FormSignUp = ({ isThreeMonth }) => {
	const intl = useIntl();

	const [value, setValue] = useState({
		name: '',
		company: '',
		filed_of_work: '',
		phone: '',
		interested: [],
		other: '',
		legal_company: '',
		email_company: '',
		business_category: '',
	});
	const [createId, setCreateId] = useState('');
	const [errorApi, setErrorApi] = useState('');
	const parsedQueryString =
		typeof window !== 'undefined' ? queryString.parse(window.location.search) : '';
	let fromUrl = '';
	if (parsedQueryString && parsedQueryString.fromUrl) {
		fromUrl = parsedQueryString.fromUrl;
	}
	const [step, setStep] = useState(1);
	const [isLoading, setIsLoading] = useState(false);

	const redirceToWa = currentData => {
		let waData = '';
		waData += `${intl.formatMessage({ id: `form_sign_up.step_1.input_1.title` })} : ${
			currentData.name
		}\n`;
		// waData += `${intl.formatMessage({ id: `form_sign_up.step_1.input_2.title` })} : ${
		// 	currentData.company
		// }\n`;
		waData += `${intl.formatMessage({ id: `form_sign_up.step_2.input_1.title` })} : ${
			currentData.legal_company
		}\n`;
		waData += `${intl.formatMessage({ id: `form_sign_up.step_2.input_2.title` })} : ${
			currentData.email_company
		}\n`;
		waData += `${intl.formatMessage({ id: `form_sign_up.step_1.input_3.title` })} : ${
			currentData.filed_of_work
		}\n`;
		waData += `${intl.formatMessage({ id: `form_sign_up.step_1.input_4.title` })} : ${
			currentData.phone
		}\n`;
		waData += `${intl.formatMessage({ id: `form_sign_up.step_2.input_3.title` })} : ${
			currentData.business_category
		}\n`;
		waData += `${intl.formatMessage({
			id: `form_sign_up.step_1.input_5.title`,
		})} : ${currentData.interested.join()}\n`;
		if (currentData.other !== '') {
			waData += `${intl.formatMessage({ id: `form_sign_up.step_1.input_6.title` })} : ${
				currentData.other
			}\n`;
		}

		sendToWhatsapp(waData);
	};

	const firstStepApi = async currentData => {
		setIsLoading(true);

		const dataSend = {
			name: currentData.name,
			company_name: currentData.legal_company,
			email: currentData.email_company,
			field_of_work: currentData.filed_of_work,
			phone: `+62${currentData.phone}`,
			from_url: fromUrl,
		};

		try {
			const promiseSendMail = sendMail(dataSend);
			const promiseAPI = await createApi(dataSend);

			const results = await Promise.all([promiseSendMail, promiseAPI]);

			setIsLoading(false);
			setCreateId(results[1].data.data.id);
			setStep(2);
		} catch (e) {
			setIsLoading(false);
			setErrorApi(e.message);
		}
	};

	const secondStepApi = async currentData => {
		setIsLoading(true);

		const dataInterest = currentData.interested.map(v => {
			if (v === intl.formatMessage({ id: `form_sign_up.step_1.input_5.items.value_5` })) {
				return `${intl.formatMessage({
					id: `form_sign_up.step_1.input_5.items.value_5`,
				})}(${currentData.other})`;
			}

			return v;
		});

		const dataSendApi = {
			interests: dataInterest.join(),
			business_category: currentData.business_category,
		};

		const dataSend = {
			...dataSendApi,
			name: currentData.name,
			company_name: currentData.legal_company,
			email: currentData.email_company,
			field_of_work: currentData.filed_of_work,
			phone: `+62${currentData.phone}`,
			other: currentData.other,
			from_url: fromUrl,
		};

		const dataSetContext = {
			context: 'web_form',
			app_id: 'Chatbiz-INAPP-Chatbiz',
			user_id: `62${currentData.phone}`,
		};

		try {
			const promiseSendMail = sendMail(dataSend);
			const promiseAPI = updateApi(createId, dataSendApi);
			const promiseContext = await setContext(dataSetContext);

			const results = await Promise.all([promiseSendMail, promiseAPI, promiseContext]);

			setIsLoading(false);
			if (results[1].data.statusCode === 200 && results[2].data.message === 'success') {
				// send form submission to GTM
				if (window) {
					window.dataLayer = window.dataLayer || [];
					window.dataLayer.push({
						event: 'formSubmission',
						formType: 'CTA',
						formPosition: 'Step 2',
					});
				}

				const sendToWaData = {
					...currentData,
					...dataSend,
				};
				redirceToWa(sendToWaData);
			}
		} catch (e) {
			setIsLoading(false);
			setErrorApi(e.message);
		}
	};

	return (
		<Wrapper>
			{step === 1 && (
				<FirstStep
					isLoading={isLoading}
					savedValue={value}
					onSave={async data => {
						const currentData = {
							...value,
							name: data.name,
							legal_company: data.legal_company,
							email_company: data.email_company,
							company: data.company,
							filed_of_work: data.filed_of_work,
							phone: data.phone,
						};
						setValue(currentData);
						// setStep(2);
						await firstStepApi(currentData);
					}}
					fromUrl={fromUrl}
					errorApi={errorApi}
					clearErrorApi={() => {
						setErrorApi('');
					}}
					isThreeMonth={isThreeMonth}
				/>
			)}
			{step === 2 && (
				<SecondStep
					savedValue={value}
					onSave={async data => {
						const currentData = {
							...value,
							business_category: data.business_category,
							interested: data.interested,
							other: data.other,
						};
						setValue(currentData);
						await secondStepApi(currentData);
					}}
					onGoBack={() => {
						setStep(1);
					}}
					isLoading={isLoading}
					fromUrl={fromUrl}
					errorApi={errorApi}
					clearErrorApi={() => {
						setErrorApi('');
					}}
					isThreeMonth={isThreeMonth}
				/>
			)}
		</Wrapper>
	);
};

FormSignUp.propTypes = {
	isThreeMonth: PropTypes.bool.isRequired,
};
export default FormSignUp;
