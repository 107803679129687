import React from 'react';

import PropTypes from 'prop-types';
import LabelError from '../../Label/ErrorForm';
import { Wrapper } from './style';

const InputPhoneWithLabel = ({
	className,
	value,
	name,
	label,
	idInput,
	placeholder,
	onChange,
	textError,
	compError,
	isError,
	type,
	isRequired,
}) => {
	// return <Wrapper className={className || 'inpDefault'} type="text" />;

	return (
		<Wrapper className={className || 'form-group inputWithLabelDefault'}>
			<label className="titleForm" htmlFor={idInput}>
				{label}
				{isRequired && <span className="required">*</span>}
			</label>
			<div className="selectInputWrapper">
				<select>
					<option>+62</option>
				</select>
				<input
					type={type}
					className={isError ? 'form-control invalidForm' : 'form-control'}
					id={idInput}
					placeholder={placeholder}
					onChange={e => {
						onChange(e);
					}}
					name={name}
					value={value}
				/>
			</div>

			{textError && isError && <LabelError>{textError}</LabelError>}
			{compError && isError && <LabelError>{compError()}</LabelError>}
		</Wrapper>
	);
};

InputPhoneWithLabel.defaultProps = {
	className: '',
	textError: '',
	type: 'text',
	compError: null,
	isError: false,
	isRequired: false,
};

InputPhoneWithLabel.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	idInput: PropTypes.string.isRequired,
	placeholder: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	textError: PropTypes.string,
	compError: PropTypes.func,
	isError: PropTypes.bool,
	type: PropTypes.string,
	isRequired: PropTypes.bool,
};

export default InputPhoneWithLabel;
