/* eslint-disable no-param-reassign */
import axios from 'axios';

const config = {
	baseURL: process.env.REACT_APP_SIGN_UP_API,
};

const ctaAxios = axios.create(config);

export const createApi = async body => {
	return ctaAxios.post(`customer-leads`, body);
};

export const updateApi = async (id, body) => {
	return ctaAxios.patch(`customer-leads/${id}`, body);
};
