import axios from 'axios';

const setContext = data => {
	return axios.post(
		'https://tnfmk55qgl.execute-api.ap-southeast-1.amazonaws.com/prod/setContext',
		data,
	);
};

export default setContext;
