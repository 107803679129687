import styled from 'styled-components';

export const Wrapper = styled.section`
	&#clear-menu-section {
		.navbar {
			height: 5rem;
		}
		.cLightChatbiz {
			background: #f9fbff;
		}
	}
`;

export default Wrapper;
