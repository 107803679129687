/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-prop-types */
import React, { useEffect, useState } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import animateScrollTo from 'animated-scroll-to';

import H2 from '../../../../atoms/Label/Headers/H2/Clear';
import InputWithLabel from '../../../../atoms/Input/Text/WithLabel';
import InputSelectWithLabel from '../../../../atoms/Input/Select/WithLabel';
import ButtonPrimary from '../../../../atoms/Button/Primary';
import ButtonLink from '../../../../atoms/Button/Link';
import EllipsisWhite from '../../../../atoms/Loading/EllipsisWhite';
import LabelError from '../../../../atoms/Label/ErrorForm';
import validateForm from './validate';
import InputCheckbox from '../../../../atoms/Input/Checkbox';
import { FormWrapper } from '../style';

const SecondStep = ({
	savedValue,
	onSave,
	onGoBack,
	isLoading,
	fromUrl,
	errorApi,
	clearErrorApi,
	isThreeMonth,
}) => {
	const intl = useIntl();
	const [isShowError, setShowError] = useState(false);
	const [shouldScroll, setShouldScroll] = useState(true);
	const { handleSubmit, submitForm, handleChange, values, errors, setFieldValue } = useFormik({
		initialValues: {
			business_category: savedValue.business_category,
			interested: savedValue.interested,
			other: savedValue.other,
		},
		enableReinitialize: true,
		validate: v => {
			return validateForm(v, intl);
		},
		validateOnChange: isShowError,
		validateOnBlur: isShowError,
		onSubmit: dataSubmit => {
			onSave(dataSubmit);
		},
	});

	useEffect(() => {
		animateScrollTo(document.querySelector('#form-daftar-2'), {
			verticalOffset: -80,
		});
	}, []);

	useEffect(() => {
		const formError = Object.keys(errors);
		if (formError.length > 0 && shouldScroll) {
			animateScrollTo(document.querySelector(`#${formError[0]}`), {
				verticalOffset: -120,
			});
			setShouldScroll(false);
		}
	}, [errors, shouldScroll]);

	return (
		<FormWrapper onSubmit={handleSubmit} id={isThreeMonth ? 'form-daftar-ads-2' : 'form-daftar-2'}>
			<div className="txtStep">
				<span className="bolder">Step 2</span>/2
			</div>
			<H2 className="h2Default formTitle">
				{intl.formatMessage({ id: `form_sign_up.step_2.title` })}
			</H2>

			<InputSelectWithLabel
				label={intl.formatMessage({ id: `form_sign_up.step_2.input_3.title` })}
				placeholder={intl.formatMessage({ id: `form_sign_up.step_2.input_3.placeholder` })}
				placeholderSearch={intl.formatMessage({
					id: `form_sign_up.step_2.input_3.placeholder_search`,
				})}
				idInput="business_category"
				name="business_category"
				onChange={data => {
					setFieldValue('business_category', data);
					clearErrorApi();
				}}
				value={values.business_category}
				textError={errors.business_category}
				isError={errors.business_category}
				lists={[
					intl.formatMessage({ id: `form_sign_up.step_2.input_3.items.value_1` }),
					intl.formatMessage({ id: `form_sign_up.step_2.input_3.items.value_2` }),
					intl.formatMessage({ id: `form_sign_up.step_2.input_3.items.value_3` }),
					intl.formatMessage({ id: `form_sign_up.step_2.input_3.items.value_4` }),
					intl.formatMessage({ id: `form_sign_up.step_2.input_3.items.value_5` }),
					intl.formatMessage({ id: `form_sign_up.step_2.input_3.items.value_6` }),
					intl.formatMessage({ id: `form_sign_up.step_2.input_3.items.value_7` }),
					intl.formatMessage({ id: `form_sign_up.step_2.input_3.items.value_8` }),
					intl.formatMessage({ id: `form_sign_up.step_2.input_3.items.value_9` }),
					intl.formatMessage({ id: `form_sign_up.step_2.input_3.items.value_10` }),
				]}
				isRequired
			/>

			<InputCheckbox
				label={intl.formatMessage({ id: `form_sign_up.step_1.input_5.title` })}
				name="interested"
				toggleChecked={v => {
					const currentData = [...values.interested];
					const findIndex = currentData.indexOf(v);
					if (findIndex > -1) {
						currentData.splice(findIndex, 1);
					} else {
						currentData.push(v);
					}
					setFieldValue('interested', currentData);
					clearErrorApi();
				}}
				lists={[
					intl.formatMessage({ id: `form_sign_up.step_1.input_5.items.value_1` }),
					intl.formatMessage({ id: `form_sign_up.step_1.input_5.items.value_2` }),
					intl.formatMessage({ id: `form_sign_up.step_1.input_5.items.value_3` }),
					intl.formatMessage({ id: `form_sign_up.step_1.input_5.items.value_4` }),
					intl.formatMessage({ id: `form_sign_up.step_1.input_5.items.value_5` }),
				]}
				values={values.interested}
				textError={errors.interested}
				isError={errors.interested}
				isRequired
				idLabel="interested"
			/>

			{values.interested.indexOf(
				intl.formatMessage({ id: `form_sign_up.step_1.input_5.items.value_5` }),
			) > -1 && (
				<InputWithLabel
					label={intl.formatMessage({ id: `form_sign_up.step_1.input_6.title` })}
					placeholder={intl.formatMessage({ id: `form_sign_up.step_1.input_6.placeholder` })}
					idInput="other"
					name="other"
					onChange={data => {
						handleChange(data);
						clearErrorApi();
					}}
					value={values.other}
					textError={errors.other}
					isError={errors.other}
					disabled={
						!values.interested.includes(
							intl.formatMessage({ id: `form_sign_up.step_1.input_5.items.value_5` }),
						)
					}
				/>
			)}

			<input type="hidden" value={fromUrl} name="fromUrl" id="fromUrl" />

			{errorApi && <LabelError>{errorApi}</LabelError>}
			<div className="buttonWrapper">
				<ButtonLink type="button" onClick={onGoBack} isDisable={isLoading}>
					{intl.formatMessage({ id: `form_sign_up.step_2.back` })}
				</ButtonLink>
				<ButtonPrimary
					onClick={() => {
						submitForm();
						setShowError(true);
					}}
					isDisable={isLoading}
					type="submit"
					id="form-selesai"
				>
					{isLoading ? <EllipsisWhite /> : intl.formatMessage({ id: `form_sign_up.step_2.button` })}
				</ButtonPrimary>
			</div>
		</FormWrapper>
	);
};

SecondStep.propTypes = {
	savedValue: PropTypes.any.isRequired,
	onSave: PropTypes.func.isRequired,
	onGoBack: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	fromUrl: PropTypes.string.isRequired,
	errorApi: PropTypes.string.isRequired,
	clearErrorApi: PropTypes.func.isRequired,
	isThreeMonth: PropTypes.bool.isRequired,
};

export default SecondStep;
