export default (values, intl) => {
	const errors = {};

	if (!values.business_category) {
		errors.business_category = intl.formatMessage({ id: `validate_form.required` });
	}
	if (values.interested.length === 0) {
		errors.interested = intl.formatMessage({ id: `validate_form.required` });
	}

	return errors;
};
