import React from 'react';
import { Wrapper } from './style';

const MenuClear = () => (
	<Wrapper id="clear-menu-section">
		<div className="cLightChatbiz">
			<div className="row">
				<nav className="navbar fixed-top navbar-light bg-white">
					<div className="container">
						<a className="navbar-brand" href="/">
							<img src="https://seiturju.sirv.com/Chatbiz/homepage/ChatbizFooter.svg" alt="Chatbiz Logo" />
						</a>
					</div>
				</nav>
			</div>
		</div>
	</Wrapper>
);

export default MenuClear;
