import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import LabelError from '../../Label/ErrorForm';

const Wrapper = styled.div`
	&.inputWithLabelDefault {
		label.titleForm {
			font-family: Montserrat;
			font-style: normal;
			font-weight: bold;
			font-size: 1rem;
			line-height: 150%;
			color: #4f4f4f;
			.required {
				color: #eb5757;
			}
		}
		input {
			background: #ffffff;
			border: 2px solid #bbdde6;
			box-sizing: border-box;
			border-radius: 6px;
			min-height: 42px;
			&.invalidForm {
				border: 1px solid rgb(235, 87, 87);
			}
			&::placeholder {
				color: #828282;
			}
			&:disabled {
				border: 1px solid #bfcdd8;
				background: #f3f5f6;
			}
		}
	}
`;

const InputWithLabel = ({
	className,
	value,
	name,
	label,
	idInput,
	placeholder,
	onChange,
	textError,
	compError,
	isError,
	type,
	disabled,
	isRequired,
}) => {
	// return <Wrapper className={className || 'inpDefault'} type="text" />;

	return (
		<Wrapper className={className || 'form-group inputWithLabelDefault'}>
			<label className="titleForm" htmlFor={idInput}>
				{label}
				{isRequired && <span className="required">*</span>}
			</label>
			<input
				type={type}
				className={isError ? 'form-control invalidForm' : 'form-control'}
				id={idInput}
				placeholder={placeholder}
				onChange={onChange}
				name={name}
				value={value}
				disabled={disabled}
			/>

			{textError && isError && <LabelError>{textError}</LabelError>}
			{compError && isError && <LabelError>{compError()}</LabelError>}
		</Wrapper>
	);
};

InputWithLabel.defaultProps = {
	className: '',
	textError: '',
	type: 'text',
	compError: null,
	isError: false,
	disabled: false,
	isRequired: false,
};

InputWithLabel.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	idInput: PropTypes.string.isRequired,
	placeholder: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	textError: PropTypes.string,
	compError: PropTypes.func,
	isError: PropTypes.bool,
	type: PropTypes.string,
	disabled: PropTypes.bool,
	isRequired: PropTypes.bool,
};

export default InputWithLabel;
