import styled from 'styled-components';

export const MOBILE_BREAKPOINT = 600;

export const Wrapper = styled.div`
	&.formGroupRadio {
		label.titleForm {
			font-family: Montserrat;
			font-style: normal;
			font-weight: bold;
			font-size: 1rem;
			line-height: 150%;
			color: #4f4f4f;
			.required {
				color: #eb5757;
			}
		}
	}
`;

export const WrapperRadio = styled.div`
	&.defaultRadioInput {
		display: flex;
		flex-wrap: wrap;
		.containerCheckbox {
			width: 48%;
			display: flex;
			align-items: center;
			position: relative;

			margin-bottom: 12px;
			cursor: pointer;
			font-family: Nunito, sans-serif;
			font-style: normal;
			font-weight: normal;
			font-size: 0.75rem;
			line-height: 150%;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			background: #ffffff;
			/* Medium Gray */

			border: 1px solid #bfcdd8;
			box-sizing: border-box;
			border-radius: 6px;
			padding: 0rem 0.5rem 0rem 2.5rem;
			min-height: 46px;

			&:nth-child(odd) {
				margin-right: 1.063rem;
				@media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
					margin-right: 0;
				}
			}

			@media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
				width: 100%;
			}

			&.active {
				border: 1px solid #009ddb;
				background: #f9fbff;
				color: #009ddb;
			}
		}

		.containerCheckbox input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
		}

		.checkmark {
			position: absolute;
			top: 12px;
			left: 7px;
			height: 18px;
			width: 18px;
			background-color: transparent;
			border: 1px solid #bfcdd8;
			border-radius: 2px;
			&:after {
				left: 5px;
				top: 2px;
				width: 5px;
				height: 10px;
				border: solid #009ddb;
				border-width: 0 2px 2px 0;
				-webkit-transform: rotate(45deg);
				-ms-transform: rotate(45deg);
				transform: rotate(45deg);
			}
		}

		.containerCheckbox:hover input ~ .checkmark {
			background-color: #ccc;
		}

		.containerCheckbox input:checked ~ .checkmark {
			background-color: transparent;

			border: 1px solid #009ddb;
		}

		.containerCheckbox input:checked ~ .checkmark:after {
			display: block;
		}

		.checkmark:after {
			content: '';
			position: absolute;
			display: none;
		}

		.containerCheckbox input:checked ~ .checkmark:after {
			display: block;
		}
	}
`;

export default WrapperRadio;
