/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import H1 from '../../../atoms/Label/Headers/H1/clear';
import Paragraph from '../../../atoms/Label/Paragraph/Clear';

import { Wrapper } from './style';

const BannerContactUs = () => {
	const intl = useIntl();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Wrapper className="banner-home">
			<div className="container container-mobile">
				<div className="row rowBannerHome">
					<div className="col-12">
						<div className="textWrapper">
							<H1 className="topIndex h1Default titleBanner">
								{intl.formatMessage({ id: `form_sign_up.title` })}
							</H1>

							<Paragraph className="topIndex pDefault bannerSubTitle">
								{intl.formatMessage({ id: `form_sign_up.sub` })}
							</Paragraph>
						</div>
					</div>
				</div>
			</div>

			<div className="quarterCircle" />
		</Wrapper>
	);
};

export default BannerContactUs;
