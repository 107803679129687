import React from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'gatsby-plugin-intl';

import Favicon from '../../assets/img/favicon.ico';
import SignUp from '../../componentsAtomic/templates/V4/SignUp';
import useDetectId from '../../hooks/useDetectId';
import NotFound from '../404';
import '../../assets/v2/default.scss';

const Render = () => {
	const intl = useIntl();
	const { isId } = useDetectId();
	if (isId) return <NotFound />;

	return (
		<>
			<Helmet title={intl.formatMessage({ id: `form_sign_up.meta.title` })}>
				<meta name="description" content={intl.formatMessage({ id: `form_sign_up.meta.desc` })} />
				<meta name="author" content="Chatbiz" />
				<meta name="theme-color" content="#04589b" />
				<link rel="icon" href={Favicon} />
				<html lang="id" />
				<link rel="stylesheet" href="/normalize.css" />
				<link rel="stylesheet" href="/default.css" />
				<meta name="robots" content="noindex, nofollow" />
			</Helmet>
			<SignUp />
		</>
	);
};

export default Render;
