import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
	&.errTxtDefault {
		font-family: Montserrat, sans-serif;
		color: #eb5757;
		margin-top: 0.5rem;
		font-size: 0.8rem;
		line-height: 150%;
	}
`;

const ErrorForm = ({ children, className }) => {
	return <Wrapper className={className || 'errTxtDefault'}>{children}</Wrapper>;
};

ErrorForm.defaultProps = {
	className: '',
};

ErrorForm.propTypes = {
	className: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default ErrorForm;
