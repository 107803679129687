export const emailValidation = email => {
	const re = new RegExp(
		/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
	);
	if (!re.test(email)) return false;
	return true;
};

export const phoneValidation = phoneNumber => {
	const regexPhone = RegExp(`^[0-9+-\\s]+$`);
	if (!regexPhone.test(phoneNumber)) return false;

	return true;
};
