import styled from 'styled-components';
import { Mobile } from '../../../atoms/_Variable/var';

export const Wrapper = styled.section`
	background-color: #f9fbff;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const FormWrapper = styled.form`
	background: #ffffff;
	box-shadow: 0px 1px 16px rgba(0, 69, 173, 0.15);
	backdrop-filter: blur(24px);
	border-radius: 16px;
	width: 650px;

	margin-top: -100px;
	padding: 2.5rem 5rem;
	margin-bottom: 11.5rem;
	@media screen and (max-width: ${Mobile}) {
		padding: 2.5rem 0.875rem;
		margin-right: 1.25rem;
		margin-left: 1.25rem;
	}

	.txtStep {
		font-family: Nunito, sans-serif;
		font-style: normal;
		font-size: 0.875rem;
		line-height: 150%;
		color: #4f4f4f;
		span.bolder {
			font-weight: bold;
		}
	}
	.formTitle {
		color: #ff8f1c;
		margin-bottom: 2.5rem;
	}

	.titleForm {
		font-family: Nunito, sans-serif;
		font-style: normal;
		font-weight: normal !important;
		font-size: 0.875rem !important;
		line-height: 150%;
		margin-top: 1.25rem;
	}

	.inputSelectWithLabelDefault {
		.floatingWrapper {
			width: 100%;
		}
	}
	input::placeholder {
		font-size: 0.875rem;
		font-family: Nunito, sans-serif;
		font-style: normal;
		font-weight: normal;
		line-height: 150%;
	}
	.buttonWrapper {
		display: flex;
		justify-content: flex-end;
		margin-top: 2.5rem;
		.btnLinkDefault {
			font-family: Montserrat;
			font-style: normal;
			font-weight: bold;
			font-size: 0.813rem;
			margin-right: 2.063rem;
		}
	}
`;
