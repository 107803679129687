/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-prop-types */
import React, { useState, useEffect } from 'react';
import animateScrollTo from 'animated-scroll-to';

import { useIntl } from 'gatsby-plugin-intl';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';

import H2 from '../../../../atoms/Label/Headers/H2/Clear';
import InputWithLabel from '../../../../atoms/Input/Text/WithLabel';
import InputPhoneWithLabel from '../../../../atoms/Input/Phone/WithLabel';
import InputSelectWithLabel from '../../../../atoms/Input/Select/WithLabel';

import ButtonPrimary from '../../../../atoms/Button/Primary';
import EllipsisWhite from '../../../../atoms/Loading/EllipsisWhite';
import LabelError from '../../../../atoms/Label/ErrorForm';
import validateForm from './validate';
import { FormWrapper } from '../style';

const FirstStep = ({
	savedValue,
	onSave,
	isLoading,
	fromUrl,
	errorApi,
	clearErrorApi,
	isThreeMonth,
}) => {
	const [isShowError, setShowError] = useState(false);
	const [shouldScroll, setShouldScroll] = useState(true);
	const intl = useIntl();
	const { handleSubmit, handleChange, values, errors, setFieldValue, submitForm } = useFormik({
		initialValues: {
			name: savedValue.name,
			legal_company: savedValue.legal_company,
			email_company: savedValue.email_company,
			filed_of_work: savedValue.filed_of_work,
			phone: savedValue.phone,
		},
		validate: v => {
			return validateForm(v, intl);
		},
		validateOnChange: isShowError,
		validateOnBlur: isShowError,
		enableReinitialize: true,
		onSubmit: dataSubmit => {
			onSave(dataSubmit);
		},
	});

	useEffect(() => {
		const formError = Object.keys(errors);
		if (formError.length > 0 && shouldScroll) {
			animateScrollTo(document.querySelector(`#${formError[0]}`), {
				verticalOffset: -120,
			});
			setShouldScroll(false);
		}
	}, [errors, shouldScroll]);

	return (
		<FormWrapper onSubmit={handleSubmit} id={isThreeMonth ? 'form-daftar-ads-1' : 'form-daftar-1'}>
			<div className="txtStep">
				<span className="bolder">Step 1</span>/2
			</div>
			<H2 className="h2Default formTitle">
				{intl.formatMessage({ id: `form_sign_up.step_1.title` })}
			</H2>
			<InputWithLabel
				label={intl.formatMessage({ id: `form_sign_up.step_1.input_1.title` })}
				placeholder={intl.formatMessage({ id: `form_sign_up.step_1.input_1.placeholder` })}
				idInput="name"
				name="name"
				onChange={data => {
					handleChange(data);
					clearErrorApi();
				}}
				value={values.name}
				textError={errors.name}
				isError={errors.name}
				isRequired
			/>
			<InputWithLabel
				label={intl.formatMessage({ id: `form_sign_up.step_2.input_1.title` })}
				placeholder={intl.formatMessage({ id: `form_sign_up.step_2.input_1.placeholder` })}
				idInput="legal_company"
				name="legal_company"
				onChange={data => {
					handleChange(data);
					clearErrorApi();
				}}
				value={values.legal_company}
				textError={errors.legal_company}
				isError={errors.legal_company}
				isRequired={false}
			/>

			<InputWithLabel
				label={intl.formatMessage({ id: `form_sign_up.step_2.input_2.title` })}
				placeholder={intl.formatMessage({ id: `form_sign_up.step_2.input_2.placeholder` })}
				idInput="email_company"
				name="email_company"
				onChange={data => {
					handleChange(data);
					clearErrorApi();
				}}
				value={values.email_company}
				textError={errors.email_company}
				isError={errors.email_company}
				isRequired
			/>

			<InputSelectWithLabel
				label={intl.formatMessage({ id: `form_sign_up.step_1.input_3.title` })}
				placeholder={intl.formatMessage({ id: `form_sign_up.step_1.input_3.placeholder` })}
				placeholderSearch={intl.formatMessage({
					id: `form_sign_up.step_1.input_3.placeholder_search`,
				})}
				idInput="filed_of_work"
				name="filed_of_work"
				onChange={data => {
					setFieldValue('filed_of_work', data);
					clearErrorApi();
				}}
				value={values.filed_of_work}
				textError={errors.filed_of_work}
				isError={errors.filed_of_work}
				lists={[
					intl.formatMessage({ id: `form_sign_up.step_1.input_3.items.value_1` }),
					intl.formatMessage({ id: `form_sign_up.step_1.input_3.items.value_2` }),
					intl.formatMessage({ id: `form_sign_up.step_1.input_3.items.value_3` }),
					intl.formatMessage({ id: `form_sign_up.step_1.input_3.items.value_4` }),
					intl.formatMessage({ id: `form_sign_up.step_1.input_3.items.value_5` }),
				]}
				isRequired
			/>

			<InputPhoneWithLabel
				label={intl.formatMessage({ id: `form_sign_up.step_1.input_4.title` })}
				placeholder={intl.formatMessage({ id: `form_sign_up.step_1.input_4.placeholder` })}
				idInput="phone"
				name="phone"
				onChange={data => {
					handleChange(data);
					clearErrorApi();
				}}
				value={values.phone}
				textError={errors.phone}
				isError={errors.phone}
				isRequired
			/>

			<input type="hidden" value={fromUrl} name="fromUrl" id="fromUrl" />

			{errorApi && <LabelError>{errorApi}</LabelError>}

			<div className="buttonWrapper">
				<ButtonPrimary
					onClick={() => {
						submitForm();
						setShowError(true);
					}}
					isDisable={isLoading}
				>
					{isLoading ? <EllipsisWhite /> : intl.formatMessage({ id: `form_sign_up.step_1.button` })}
				</ButtonPrimary>
			</div>
		</FormWrapper>
	);
};

FirstStep.propTypes = {
	savedValue: PropTypes.any.isRequired,
	onSave: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	fromUrl: PropTypes.string.isRequired,
	errorApi: PropTypes.string.isRequired,
	clearErrorApi: PropTypes.func.isRequired,
	isThreeMonth: PropTypes.bool.isRequired,
};

export default FirstStep;
