import styled from 'styled-components';

export const Wrapper = styled.div`
	&.inputSelectWithLabelDefault {
		position: relative;
		width: 100%;

		label.titleForm {
			font-family: Montserrat;
			font-style: normal;
			font-weight: bold;
			font-size: 1rem;
			line-height: 150%;
			color: #4f4f4f;
			.required {
				color: #eb5757;
			}
		}
		.form-control {
			appearance: none;
			background: url(https://seiturju.sirv.com/Chatbiz/ico-expand-more.svg) no-repeat 97% 15px !important;
		}
		input {
			background: #ffffff;
			border: 2px solid #bbdde6;
			box-sizing: border-box;
			border-radius: 6px;
			min-height: 42px;
			&.invalidForm {
				border: 1px solid rgb(235, 87, 87);
			}
			&::placeholder {
				color: #828282;
			}
		}
	}
`;

export const FloatingWrapper = styled.div`
	&.floatingWrapper {
		position: absolute;
		background: #ffffff;
		border: 1px solid #bbdde6;
		box-sizing: border-box;
		box-shadow: 0px 4px 16px rgba(0, 69, 173, 0.15);
		border-radius: 6px;
		padding: 1.188rem 0.938rem;
		margin-top: 3px;
		width: 100%;
		z-index: 99;
		input {
			width: 100%;
			padding-left: 0.813rem;
		}
		ul {
			list-style: none;
			max-height: 200px;
			overflow: auto;
			li {
				margin-top: 1.188rem;
				cursor: pointer;
			}
		}
	}
`;

export default Wrapper;
