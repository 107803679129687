/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import OutsideClickHandler from 'react-outside-click-handler';
import LabelError from '../../Label/ErrorForm';
import ParagraphSmall from '../../Label/Paragraph/Small';
import { SVGRender } from '../../../../helper/image';
import { Wrapper, FloatingWrapper } from './style';

const InputSelectWithLabel = ({
	className,
	value,
	name,
	label,
	idInput,
	placeholder,
	onChange,
	textError,
	compError,
	isError,
	type,
	placeholderSearch,
	lists,
	isRequired,
}) => {
	const [isOpen, setOpen] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [search, setSearch] = useState('');

	const renderList = () => {
		const filterItems = needle => {
			const query = needle.toLowerCase();
			return lists.filter(item => item.toLowerCase().indexOf(query) >= 0);
		};
		return filterItems(search).map(v => (
			<li
				key={v}
				onClick={() => {
					onChange(v);
					setOpen(false);
					setOpenModal(false);
				}}
				onKeyDown={() => {
					onChange(v);
					setOpen(false);
					setOpenModal(false);
				}}
			>
				<ParagraphSmall>{v}</ParagraphSmall>
			</li>
		));
	};

	const renderSearch = (
		<>
			<input
				type="text"
				placeholder={placeholderSearch}
				value={search}
				onChange={e => {
					setSearch(e.target.value);
				}}
			/>
			<ul>{renderList()}</ul>
		</>
	);

	return (
		<Wrapper className={className || 'form-group inputSelectWithLabelDefault'}>
			<label className="titleForm" htmlFor={idInput}>
				{label}
				{isRequired && <span className="required">*</span>}
			</label>
			<input
				type={type}
				className={isError ? 'form-control invalidForm' : 'form-control'}
				id={idInput}
				placeholder={placeholder}
				onChange={onChange}
				name={name}
				value={value}
				readOnly
				onClick={() => {
					if (typeof window !== 'undefined' && window.innerWidth <= 480) {
						setOpenModal(true);
					} else {
						setOpen(true);
					}
				}}
			/>

			{textError && isError && <LabelError>{textError}</LabelError>}
			{compError && isError && <LabelError>{compError()}</LabelError>}

			{isOpen && (
				<OutsideClickHandler
					onOutsideClick={() => {
						setOpen(false);
					}}
				>
					<FloatingWrapper className="floatingWrapper">{renderSearch}</FloatingWrapper>
				</OutsideClickHandler>
			)}
			<Modal
				isOpen={openModal}
				contentLabel="SelectInputModal"
				overlayClassName="OverlaySelectInputeModal"
			>
				<div className="modal-select-wrapper">
					<div className="modal-content">
						<div className="close-btn">
							<button
								type="button"
								onClick={() => {
									setOpenModal(false);
								}}
							>
								{SVGRender('https://seiturju.sirv.com/Chatbiz/ico-close.svg', 'ico-close')}
							</button>
						</div>
						{renderSearch}
					</div>
				</div>
			</Modal>
		</Wrapper>
	);
};

InputSelectWithLabel.defaultProps = {
	className: '',
	textError: '',
	type: 'text',
	compError: null,
	isError: false,
	isRequired: false,
};

InputSelectWithLabel.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	idInput: PropTypes.string.isRequired,
	placeholder: PropTypes.string.isRequired,
	placeholderSearch: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	textError: PropTypes.string,
	compError: PropTypes.func,
	isError: PropTypes.bool,
	type: PropTypes.string,
	lists: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
	isRequired: PropTypes.bool,
};

export default InputSelectWithLabel;
