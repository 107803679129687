import styled from 'styled-components';

export const Wrapper = styled.div`
	&.inputWithLabelDefault {
		label.titleForm {
			font-family: Montserrat;
			font-style: normal;
			font-weight: bold;
			font-size: 1rem;
			line-height: 150%;
			color: #4f4f4f;
			.required {
				color: #eb5757;
			}
		}
		.selectInputWrapper {
			display: flex;
		}

		select {
			margin-right: 0.5rem;
			min-width: 100px;
			font-family: Nunito, sans-serif;
			font-style: normal;
			font-weight: normal;
			font-size: 0.875rem;
			line-height: 150%;
			padding-left: 0.813rem;
			appearance: none;
			background: url(https://seiturju.sirv.com/Chatbiz/ico-expand-more.svg) no-repeat 90% 15px !important;
			&:focus {
				outline: 0;
				box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
			}
		}
		input,
		select {
			background: #ffffff;
			border: 2px solid #bbdde6;
			box-sizing: border-box;
			border-radius: 6px;
			min-height: 42px;
			&.invalidForm {
				border: 1px solid rgb(235, 87, 87);
			}
			&::placeholder {
				color: #828282;
			}
		}
	}
`;

export default Wrapper;
