/* eslint-disable import/no-unresolved */
import React from 'react';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import 'lazysizes';
import 'typeface-montserrat';
import 'bootstrap/dist/css/bootstrap.css';
import '@fontsource/nunito';

import Menu from '../../../organisms/v2/Menu';
import MenuClear from '../../../organisms/V4/Menu/Clear';
import BannerSignUp from '../../../organisms/V4/BannerSignUp';
import FormSignUp from '../../../organisms/V4/FormSignUp';
import Footer from '../../../organisms/Footer';
import withDefautlHoc from '../../../_hoc/global';

import withIntl from '../../../../helper/hoc/withIntl';

const SignUp = ({ enPrefix }) => {
	const navigateToFormSignUp = () => {
		let currentUrl = '';
		if (typeof window !== 'undefined') {
			currentUrl = window.location.href;
		}
		if (enPrefix) {
			navigate(`/en/form/signup?fromUrl=${currentUrl}`);
		} else {
			navigate(`/form/signup?lang=id&fromUrl=${currentUrl}`);
		}
	};

	const parsedQueryString =
		typeof window !== 'undefined' ? queryString.parse(window.location.search) : '';
	let fromUrl = '';
	if (parsedQueryString && parsedQueryString.fromUrl) {
		fromUrl = parsedQueryString.fromUrl;
	}

	const renderMenu = () => {
		if (fromUrl.includes('promo-3-months')) {
			return <MenuClear />;
		}

		return <Menu enPrefix={enPrefix} onDemoClick={navigateToFormSignUp} />;
	};

	return (
		<>
			{renderMenu()}
			<BannerSignUp />
			<FormSignUp isThreeMonth={fromUrl.includes('promo-3-months')} />

			<Footer enPrefix={enPrefix} />
		</>
	);
};

SignUp.propTypes = {
	enPrefix: PropTypes.string.isRequired,
};

export default withIntl(withDefautlHoc(SignUp));
