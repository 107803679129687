/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import LabelError from '../../Label/ErrorForm';
import { WrapperRadio, Wrapper } from './style';

const InputCheckbox = ({
	className,
	lists,
	name,
	label,
	toggleChecked,
	textError,
	compError,
	isError,
	idInput,
	isRequired,
	values,
	idLabel,
}) => {
	return (
		<Wrapper className={className || 'formGroupRadio'}>
			<label className="titleForm">
				{label}
				{isRequired && <span className="required">*</span>}
			</label>
			<WrapperRadio className="defaultRadioInput" id={idLabel}>
				{lists.map((v, i) => {
					const isChecked = values.includes(v);

					return (
						<div
							className={isChecked ? 'containerCheckbox active' : 'containerCheckbox'}
							onClick={() => {
								toggleChecked(v);
							}}
							onKeyDown={() => {
								toggleChecked(v);
							}}
							htmlFor={`${idInput}${i}`}
						>
							{v}
							<input type="checkbox" name={name} checked={isChecked} id={`${idInput}${i}`} />
							<span className="checkmark" />
						</div>
					);
				})}
			</WrapperRadio>
			{textError && isError && <LabelError>{textError}</LabelError>}
			{compError && isError && <LabelError>{compError()}</LabelError>}
		</Wrapper>
	);
};

InputCheckbox.defaultProps = {
	className: '',
	textError: '',
	compError: null,
	isError: false,
	isRequired: false,
	idLabel: '',
};

InputCheckbox.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string.isRequired,
	idLabel: PropTypes.string,
	values: PropTypes.arrayOf(PropTypes.string).isRequired,
	lists: PropTypes.any.isRequired,
	label: PropTypes.string.isRequired,
	toggleChecked: PropTypes.func.isRequired,
	idInput: PropTypes.string.isRequired,
	textError: PropTypes.string,
	compError: PropTypes.func,
	isError: PropTypes.bool,
	isRequired: PropTypes.bool,
};

export default InputCheckbox;
